// stylelint-disable at-rule-empty-line-before

@import "variables";
@import "functions";
@import "mixins";
@import "sprites";
@import "fonts";
@import "vendor";
@import "base";
@import "svg-icon";

body {
	font-size: 18px;
	line-height: 1.35;
	color: $color-Tundora;
}

@each $a in (300, 400, 500, 700, 900) {
	.fw-#{$a} {
		font-weight: $a;
	}
}

button {
	&:focus {
		outline: none;
	}
}

.justifyleft {
	text-align: left;
}
.justifyright {
	text-align: right;
}
img.justifyleft {
	float: left;
	margin-right: 25px;
	margin-bottom: 25px;
}
img.justifyright {
	float: right;
	margin-bottom: 25px;
	margin-left: 25px;
}

#lightbox {
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;

	.lb-outerContainer {
	}
}

p {
	margin-bottom: 25px;
	font-weight: 300;
}

.tel {
	display: block;
	color: $color-Tundora;

	&:focus {
		outline: none;
	}
}

.block-padding {
	padding-right: 5%;
	padding-left: 5%;
}

.main-block {
	margin: 120px 0;
	@media screen and (max-width:40em) {
		margin: 50px 0;
	}
}

.block-typical {
	img {
		// margin-top: 25px;
		margin-bottom: 25px;
	}
}

h2 {
	margin-top: 120px;
	@media screen and (max-width:40em) {
		margin-top: 50px;
	}
}

h2,
.block-header {
	margin-bottom: 70px;
	font-size: 26px;
	line-height: 1.35;
	color: $color-Mine-Shaft;

	@media screen and (max-width:40em) {
		margin-bottom: 30px;
		font-size: 22px;
	}
}

.separator-line {
	height: 2px;
	background: $color-Concrete;
}

.separator-line-2 {
	height: 2px;
	background: $color-Limeade;
}

.separator-line-3 {
	display: flex;
	align-items: center;
	margin-top: 70px;
	margin-bottom: 70px;
	text-align: right;

	&__line {
		height: 2px;
		background: $color-Concrete;
	}

	&__text {
		display: inline-block;
		margin-left: 15px;
		cursor: pointer;
	}
}

.button-oval,
.button-oval-fill,
.button-oval-fill-mini {
	display: inline-flex;
	align-items: center;
	outline: none;
	border-radius: 50rem;
	box-sizing: border-box;
	font-size: 18px;
	text-decoration: none;

	&:hover,
	&:active,
	&:visited,
	&:focus {
		text-decoration: none;
	}

	&__icon {
		margin-right: 9px;
		min-width: 19px;
	}
}

.button-oval-fill,
.button-oval-fill-mini {
	border: none;
	color: $color-White;
	background: $color-Bossanova;
	cursor: pointer;
}

.button-oval {
	@include transition("background-color 0.5s");

	border: 2px solid $color-Bossanova;
	padding: 8px 20px;
	color: $color-Tundora;

	&:hover,
	&:active,
	&:visited,
	&:focus {
		color: $color-Tundora;
	}

	&__icon {
		margin-right: 9px;
		min-width: 19px;
	}

	&:hover {
		color: $color-White;
		background-color: $color-Bossanova;

		#phone {
			background-image: url("../images/sprites/svg/phone-white.svg");

			svg {
				display: none;
			}
		}
	}
}

.button-oval-fill {
	padding: 10px 42px;
}

.button-oval-fill-mini {
	padding: 10px 24px;
}

.tdu {
	text-decoration: underline;
}

.d-ib {
	display: inline-block;
}

@import "blocks/logo.scss";
@import "blocks/header.scss";
@import "blocks/main-block-help.scss";
@import "blocks/main-block-reviews.scss";
@import "blocks/form-callback.scss";
@import "blocks/footer.scss";

.block-slider {
	$slide-height: 488px;

	position: relative;

	.slick-slide {
		height: $slide-height;
	}

	ul.slick-dots {
		position: absolute;
		left: 50%;
		bottom: 14px;
		z-index: 2;
		list-style-type: none;
		margin: 0;
		transform: translate(-50%);

		li {
			display: inline-block;
			margin-right: 9px;
			border: 1px solid $color-Gallery;
			border-radius: 50%;
			width: 12px;
			height: 12px;
			box-sizing: border-box;
			cursor: pointer;

			&.slick-active {
				background: $color-Gallery;
			}

			button {
				display: none;
			}
		}
	}

	// &__slide-item {
	// 	position: relative;

	// 	// &:before {
	// 	// 	content: "";
	// 	// 	position: absolute;
	// 	// 	left: 0;
	// 	// 	right: 0;
	// 	// 	top: 0;
	// 	// 	bottom: 0;
	// 	// 	z-index: 1;
	// 	// 	background: $color-Cod-Gray-062;
	// 	// }
	// }

	&__image {
		position: relative;
		width: 100%;
		height: $slide-height;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&__anchor {
		position: absolute;
		// position: relative;
		z-index: 2;
		display: flex;
		border-radius: 10px;
		padding: 5px;
		padding-left: 60px;
		font-size: 18px;
		line-height: 50px;
		text-decoration: none;
		color: $color-White;
		background: $color-Cod-Gray-062;
		opacity: 0.7;

		&:visited,
		&:active,
		&:hover {
			text-decoration: none;
			color: $color-White;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
		}

		&:before {
			left: 5px;
			top: 5px;
			border: 2px solid $color-White;
			border-radius: 50%;
			width: 50px;
			height: 50px;
			box-sizing: border-box;
		}

		&:after {
			@include triangle(to right, 16px, 17px, $color-White);

			left: 25px;
			top: 22px;
		}

		&:hover {
			opacity: 1;
		}
	}
}

.main-block-1 {
	&__block {
		@media screen and (max-width:40em) {
			margin-bottom: 25px;
		}
	}

	&__header-text {
		margin-bottom: 15px;
		font-weight: 700;
		font-size: 32px;
		line-height: 28px;
		line-height: normal;
		color: $color-Limeade;

		@media screen and (max-width:40em) {
			padding-right: 20px;
		}
	}

	&__text {
		font-weight: 300;
		font-size: 18px;
	}
}

.main-block-bg-help {
	background: url("../images/bg-sun.svg") no-repeat;
	background-position: 101% 21%;
}

.main-block-2 {
	&__block {
		padding: 0 3%;
	}

	&__block-icon {
		margin-bottom: 13px;
		height: 85px;
	}

	&__block-header {
		margin-bottom: 13px;
		font-size: 18px;
		text-align: center;
		color: $color-Mine-Shaft;
	}

	&__block-text {
		padding-bottom: 42px;
		font-weight: 300;
		font-size: 18px;
		text-align: center;
	}
}

.main-block-about-us {
	&__block {
		display: flex;
		margin: auto;
		margin-bottom: 70px;
		max-width: 829px;
	}

	&__block-icon {
		min-width: 153px;
	}

	&__block-text {
		font-weight: 300;
		font-size: 18px;
	}

	&__anchor {
		display: inline-block;
		margin-top: 30px;
		color: $color-Tundora;
	}
}

.block-collage {
	position: relative;
	margin-top: 50px;

	&__handshake {
		position: absolute;
		left: 50%;
		top: -37%;
		z-index: -1;
		width: 50%;
		height: 333px;
		max-width: 800px;
		background-image: url("../images/bg-handshake.svg");
		background-position: right;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__main {
		position: relative;
		height: 462px;
	}

	&__video {
		position: relative;
		z-index: 1;
		width: 66%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;

		@media screen and (max-width:64em) {
			width: 100%;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1;
			background: $color-Cod-Gray-062;
		}
	}

	&__video,
	&__video-anchor,
	&__img-1,
	&__img-2,
	&__img-3,
	&__text {
		position: absolute;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&__video-anchor {
		left: 50%;
		top: 50%;
		z-index: 2;
		width: 76px;
		height: 76px;
		opacity: 0.5;
		transform: translate(-50%, -50%);

		&:before,
		&:after {
			content: "";
			position: absolute;
		}

		&:before {
			left: 0;
			top: 0;
			border: 2px solid $color-White;
			border-radius: 50%;
			width: 76px;
			width: 100%;
			height: 76px;
			height: 100%;
			box-sizing: border-box;
		}

		&:after {
			@include triangle(to right, 24px, 25px, $color-White);

			left: 56%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&:visited,
		&:active,
		&:hover {
			color: $color-White;
		}

		&:hover {
			opacity: 1;
		}
	}

	&__img-1 {
		left: 66%;
		top: 0;
		width: 18%;
		height: 30%;
	}

	&__img-2 {
		left: 84%;
		top: 0;
		width: 16%;
		height: 66%;
	}

	&__img-3 {
		left: 66%;
		top: 66%;
		width: 34%;
		height: 34%;
	}

	&__text {
		left: 66%;
		top: 30%;
		padding: 3%;
		width: 18%;
		height: 36%;
		font-size: 18px;
		text-decoration: underline;
		color: $color-Tundora;
	}

	&__anchor {
		display: inline-block;
		margin-top: 30px;
		color: $color-Tundora;
	}
}

.block-slider-2 {
	margin-bottom: 70px;
	padding: 0 120px;

	@media screen and (max-width:64em) {
		padding: 0;
	}

	ul.slick-dots {
		margin-top: 15px;
		margin-right: 0;
		margin-left: 0;
		text-align: center;

		@media screen and (max-width:800px) {
			display: none!important;
		}

		li {
			position: relative;
			display: inline-block;
			border-bottom: 3px solid $color-Alto;
			padding-bottom: 5px;
			width: 50px;
			cursor: pointer;

			button {
				color: $color-Alto;
				cursor: pointer;

				&:focus {
					outline: none;
				}
			}

			&:first-child .slick-help-line {
				position: absolute;
				left: 0;
				bottom: -3px;
				z-index: 2;
				width: 100%;
				height: 3px;
				background: $color-Tundora;
				transition: transform 0.5s;
			}

			&.slick-active {
				button {
					color: $color-Tundora;
				}
			}
		}
	}

	&__slider-image {
		margin: 0 10%;
		padding-bottom: 102%;
		// height: 320px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&__slider-header {
		margin-top: 20px;
		margin-bottom: 15px;
		// min-height: 48px;
		font-size: 18px;
		text-align: center;
	}

	&__slider-text {
		font-weight: 300;
		font-size: 18px;
		text-align: center;
	}
}

.main-block-our-specialists__anchor {
	display: block;
	font-size: 18px;
	text-align: center;
	text-decoration: underline;
	color: $color-Mine-Shaft;
}

.main-block-before-footer {
	font-size: 18px;

	&__block-header {
		margin-bottom: 10px;
		// text-align: left;
	}

	&__block-text {
		margin-bottom: 24px;
		font-weight: 300;
	}
}

.block-header-2 {
	padding: 70px 0;
	background: $color-Limeade;

	&__header {
		font-size: 32px;
		color: $color-White;

		@media screen and (max-width:40em) {
			font-size: 22px;
		}
	}

	&__header-section {
		font-size: 18px;
		color: $color-Turtle-Green;
	}

	&__section {
		display: none;
		padding: 0 5%;
	}

	&__section-list {
		li {
			a {
				padding-top: 20px;
				padding-right: 40px;
				padding-bottom: 20px;
				padding-left: 0;
				font-size: 18px;
				text-decoration: underline;
				color: $color-Turtle-Green;

				&:hover {
					color: $color-White;
				}
			}
		}
	}
}

.block-before-footer-2 {
	&__floating-block {
		position: relative;
		padding: 81px 113px 95px 76px;
		box-shadow: 0 10px 25px rgba(0, 0, 0, 0.16);
		background: #fff;
	}

	&__info {
		position: absolute;
		right: 28px;
		top: -28px;
		width: 109px;
		height: 109px;

		svg {
			width: 109px;
			height: 109px;
		}
	}

	&__text {
		font-size: 20px;
	}

	&__signature {
		margin-top: 20px;
		font-size: 18px;
	}
}

.block-photo {
	.img {
		height: 156px;
		background-size: cover;
		background-repeat: no-repeat;
	}

	&.block-photo-clone {
		display: none;
	}
}
