.main-block-help {
	&__block {
		margin-bottom: 70px;
		padding: 0 5%;
	}

	&__block-icon {
		// display: inline-block;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border: 4px solid $color-Limeade;
		border-radius: 50%;
		width: 82px;
		height: 82px;
		box-sizing: border-box;
		font-weight: 700;
		font-size: 48px;
		// line-height: 82px;
		text-align: center;
		transform: translate(0,-15px);
		@media screen and (max-width:64em) {
			width: 60px;
			height: 60px;
			font-size: 30px;
		}

		@media screen and (max-width:46em) {
			width: 35px;
			height: 35px;
			font-size: 20px;
		}
	}

	&__block-header {
		margin-bottom: 13px;
		font-size: 18px;
	}

	&__block-text {
		font-weight: 300;
		font-size: 18px;
	}

	&__text {
		padding: 0 22%;
		font-size: 18px;
		text-align: center;

		@media screen and (max-width:64em) {
			padding: 0 5%;
		}
	}
}
