.footer {
	margin: 70px 0 120px;
	font-size: 18px;
	@media screen and (max-width:46em) {
		margin: 10px 0 30px;
	}

	.cell {
		margin-bottom: 60px;
		padding-right: 70px;

		@media screen and (max-width:64em) {
			padding-right: 0;
		}
	}

	&__text-1 {
		font-weight: 300;
	}

	&__text-3 {
		font-size: 14px;
	}

	&__icons {
		margin-top: 8px;
		margin-left: 3px;
	}

	&__icon-norm,
	&__icon-hover {
		position: absolute;
		left: 0;
		width: 30px;
		height: 30px;
		// transition: transform 0.3s;

		svg {
			width: 30px;
			height: 30px;
		}
	}

	&__icon-norm {
		top: 0;
	}

	&__icon-hover {
		top: -100%;
	}

	&__icon {
		position: relative;
		display: inline-block;
		overflow: hidden;
		margin-top: 10px;
		margin-right: 10px;
		width: 30px;
		height: 30px;
		text-decoration: none;

		@media screen and (max-width:64em) {
			.footer__icon-hover {
				transform: translate(0, 100%);
			}
		}

		&:hover {
			.footer__icon-hover {
				transform: translate(0, 100%);
			}
		}
	}

	#instagram {
		width: 30px;
		height: 30px;
		background: #fff url(../images/sprites/svg/instagram.svg);
		background-size: cover;
	}

	&__phone {
		margin-bottom: 20px;
		font-size: 32px;
	}
}
