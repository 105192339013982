#logo {
	display: inline-block;

	svg {
		width: 115px;
		height: 45px;
	}
}

#phone {
	display: inline-block;
	width: 19px;
	height: 19px;

	svg {
		width: 19px;
		height: 19px;
	}
}

#motivation {
	svg {
		width: 83px;
		height: 83px;
	}
}

#professionals {
	svg {
		width: 79px;
		height: 66px;
	}
}

#security {
	svg {
		width: 88px;
		height: 57px;
	}
}

#support {
	svg {
		width: 85px;
		height: 46px;
	}
}

#cost {
	svg {
		width: 63px;
		height: 63px;
	}
}

#anonymity {
	svg {
		width: 67px;
		height: 66px;
	}
}

#handshake {
	svg {
		width: 87px;
		height: 79px;
	}
}

#folder {
	svg {
		width: 74px;
		height: 98px;
	}
}

#people {
	svg {
		width: 90px;
		height: 90px;
	}
}

#birdie {
	svg {
		width: 42px;
		height: 33px;
	}
}

#big-quotes {
	svg {
		width: 125px;
		height: 109px;
	}
}

#bars {
	svg {
		width: 22px;
		height: 18px;
	}
}

#cross {
	svg {
		width: 26px;
		height: 26px;
	}
}
