.logo {
	display: flex;
	align-items: center;
	width: 265px;
	text-decoration: none;

	&:hover,
	&:active,
	&:visited {
		text-decoration: none;
	}

	&__text {
		margin-top: 8px;
		margin-left: 17px;
		width: 133px;
		font-weight: 700;
		font-size: 10px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		color: $color-Tundora;
	}
}
