.main-header {
	$time: 0s;
	$time-2: 0s;
	$time-3: 0s;

	position: relative;
	z-index: 5;
	transition: height 0.5s;

	.menu-icon {
		&:focus {
			outline: none;
		}
	}

	.menu > li > a {
		&:hover {
			color: #61b204;
		}
	}

	.dropdown.menu > li.is-dropdown-submenu-parent {
		& > a {
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				right: 0;
				// transition: background 0.5s;
				right: 0.5rem;
				top: 50%;
				margin: 0;
				border: none;
				border-radius: 50rem;
				padding: 0;
				width: 6px;
				height: 2px;
				background: $color-Tundora;
			}

			&:before {
				right: calc(0.5rem + 3px);
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}

		&.is-active {
			& > a {
				&:before,
				&:after {
					background: $color-Limeade;
				}
			}
		}
	}

	// .dropdown.menu > li.opens-right > .is-dropdown-submenu {
	.is-dropdown-submenu {
		left: 0!important;
		top: 58px!important;
		z-index: 200;
		padding-top: 30px;
		padding-bottom: 30px;
		width: 100%;
		background: $color-Limeade;
	}

	.is-dropdown-submenu-parent {
		position: static;
	}

	.is-dropdown-submenu > li {
		display: inline-block;
		width: auto;
	}

	.dropdown .is-dropdown-submenu a {
		padding-right: 90px;
		padding-bottom: 20px;
		padding-left: 0;
		color: $color-Turtle-Green;

		&:hover {
			color: $color-Alabaster;
		}
	}

	.dropdown .is-dropdown-submenu .active > a {
		color: $color-Alabaster;
	}

	.menu .active > a,
	.menu .is-active > a {
		color: $color-Limeade;
		background: none;
	}

	.title-bar {
		background: #fff;
	}

	&__first-line,
	&__second-line {
		transition: padding $time, max-height $time;
	}

	&__first-line {
		padding-top: 16px;
		padding-bottom: 0;
		max-height: 68px;

		.top-bar-left {
			overflow: hidden;
			max-height: 52px;
			transition: max-height $time, opacity $time-2;
		}

		.top-bar-right {
			position: relative;
			z-index: 5;
			transform: translate(0px, 0px);
			transition: transform $time;

			.button-oval {
				@include transition("max-width #{$time-3}, max-height #{$time-3}, padding #{$time-3}");

				overflow: hidden;
				max-width: 210px;
				white-space: nowrap;
				// , background-color 0.5s;
			}
		}
	}

	&__second-line {
		padding-top: 0;
		padding-bottom: 18px;

		.top-bar-right {
			overflow: hidden;
			max-width: 70px;
			max-height: 45px;
			transition: max-width $time, max-height $time;
		}
	}

	& .sticky {
		z-index: 5;
		box-shadow: 0 0 10px $color-Black-02;
		background: $color-White;

		#birdie {
			z-index: 2;
			margin-right: 0;
			width: 0;
			transform: translate(102px, -58px);
			transition: width $time, margin $time, transform $time, padding $time;

			svg {
				width: 13px;
				transition: width $time;
			}
		}

		&.is-stuck {
			#birdie {
				margin-right: 50px;
				width: 43px;
				transform: translate(0px, 0px);

				svg {
					width: 42px;
				}
			}

			.main-header__first-line {
				padding: 0;
				max-height: 0;

				.top-bar-left {
					max-height: 0;
					opacity: 0;
				}

				.top-bar-right {
					transform: translate(0px, 38px);

					.main-header__phone-number {
						@media screen and (max-width:1150px) {
							display: none;
						}
					}

					.button-oval {
						padding: 8px 11px;
						max-width: 43px;
						max-height: 43px;
					}
				}
			}

			.main-header__second-line {
				padding-top: 20px;

				.top-bar-right {
					max-width: 0;
					max-height: 0;
				}
			}
		}
	}

	&__phone-number {
		margin-right: 39px;
		font-size: 20px;
	}

	&__menu {
		& > li:nth-child(1) {
			& > a {
				padding-left: 0;
			}
		}

		& > li:last-child {
			& > a {
				padding-right: 0;
			}
		}

		a {
			font-size: 18px;
			color: $color-Tundora;
		}
	}

	&__help-panel {
		position: absolute;
		left: 0;
		top: calc(100%);
		z-index: 1;
		display: none;
		width: 100%;
		box-shadow: 0 0 10px $color-Black-02;
		background: $color-Limeade;
	}

	&__off-canvas {
		padding: 100px 80px 0;
	}

	&__menu-accordion {
	}
}

.reveal-overlay {
	#modal2 {
		top: 0!important;
		height: auto;
		min-height: 10px;
		box-shadow: 0 0 10px $color-Black-02;

		&:focus {
			outline: none;
		}

		.modal2-close {
			margin-top: 50px;
		}
	}

	#modal1 {
		top: 0!important;
		border: none;
		padding: 0;
		height: auto;
		min-height: 10px;
		box-shadow: 0 0 10px $color-Black-02;

		&:focus {
			outline: none;
		}

		.menu .active > a,
		.menu .is-active > a {
			background: none;
		}

		.main-header__mobile-block-1 {
			// padding-top: 0.5rem;
			// padding-bottom: 0.5rem;
		}

		.main-header__mobile-block-2 {
			padding-top: 18px;
			padding-right: 2rem;
			padding-bottom: 50px;
			padding-left: 2rem;
			background: $color-Limeade;
		}

		.main-header__menu-accordion {
			li {
				a {
					padding-left: 0;
					color: $color-Turtle-Green;
				}

				&.active {
					& > a {
						color: $color-White;
					}
				}
			}

			&.dropdown.menu > li.is-accordion-submenu-parent {
				& > a {
					position: relative;

					&:before,
					&:after {
						content: "";
						position: absolute;
						right: 0;
						// transition: background 0.5s;
						right: 0.5rem;
						top: 50%;
						margin: 0;
						border: none;
						border-radius: 50rem;
						padding: 0;
						width: 12px;
						height: 2px;
						background: $color-Tundora;
					}

					&:before {
						right: calc(0.5rem + 8px);
						transform: rotate(45deg);
					}

					&:after {
						transform: rotate(-45deg);
					}
				}

				&.active {
					& > a {
						&:before,
						&:after {
							background: $color-White;
						}
					}
				}
			}
		}

		.main-header__phone-number {
			margin: 25px 0;
			font-style: 22px;
		}
	}
}

.phone-flex {
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 1;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	box-shadow: 0 5px 8px $color-Black-02;
	background: $color-Bossanova;

	#phone {
		position: absolute;
		left: 30%;
		top: 30%;
	}

	&:focus {
		outline: none;
	}
}
