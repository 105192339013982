.helper-1 {
	min-height: 130px;
	@media screen and (max-width:64em) {
		min-height: auto;
	}
}

.main-block-reviews {
	&__block-header {
		margin-bottom: 0;
	}

	&__block-tabs {
		align-items: baseline;
		border: none;
		background: none;
	}

	&__tab {
		a {
			padding: 0;
			font-weight: 300;
			font-size: 18px;
			text-decoration: underline;
			color: $color-Tundora;
		}

		&.is-active {
			a {
				font-weight: 700;
				text-decoration: none;
				color: $color-Tundora;
			}
		}

		a:focus {
			outline: none;
		}
	}

	&__block-panels {
		margin-top: 30px;
		border: none;
	}

	&__panel {
		padding: 0;
	}

	&__small {
		margin-bottom: 10px;

		.main-block-reviews__block-header,
		.main-block-reviews__tab {
			margin: 10px;
		}
	}

	&__button-mini {
		margin-top: 35px;
	}
}

.block-slider-reviews-1,
.block-slider-reviews-2,
.block-slider-reviews-3 {
	font-size: 18px;

	&__slider-header {
		margin-bottom: 15px;
		font-weight: 700;
	}

	&__slider-text {
		margin-bottom: 18px;
	}

	&__slider-review {
		position: relative;
		z-index: 1;
		padding-top: 20px;
		padding-bottom: 70px;
		font-weight: 300;
		color: $color-White;
		background-color: $color-Limeade;
	}

	&__slider-quotes {
		position: absolute;
		left: 50%;
		top: -30px;
		z-index: 2;
		width: 50%;
		height: 109px;
		max-width: 486px;
		background-image: url("../images/sprites/svg/big-quotes.svg");
		background-position: right;
		background-repeat: no-repeat;

		@media screen and (max-width:64em) {
			display: none;
		}
	}

	&__slider-review-text {
		position: relative;
		z-index: 5;
	}

	&__slick-navigation {
		.cell {
			position: relative;
		}

		.slick-dots {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;

			@media screen and (max-width:64em) {
				position: static;
				margin-bottom: 10px;
			}

			button {
				display: none;
			}

			li {
				display: inline-block;
				border-radius: 50%;
				width: 75px;
				height: 75px;
				box-shadow: 0 0 0 0 rgba(100, 100, 100, 0.5);
				background-size: cover;
				cursor: pointer;
				// transition: width 0.5s, height 0.5s, box-shadow 0.5s;

				&:nth-child(2) {
					margin-left: 35px;
				}

				&.slick-active {
					width: 100px;
					height: 100px;
					box-shadow: 0 7px 12px -1px rgba(100, 100, 100, 0.5);
				}
			}
		}
	}
}
