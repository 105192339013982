// @import "../../node_modules/normalize-css/normalize";

@import "../../node_modules/foundation-sites/scss/foundation";

@include foundation-everything;

@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/lightbox2/2.10.0/css/lightbox.css");
// @import url("https://cdnjs.cloudflare.com/ajax/libs/slick-lightbox/0.2.12/slick-lightbox.css");
// @import url("https://cdnjs.cloud

@import "./vendor/YouTubePopUp";
