$tmp-transition-value: none;

@mixin transition($value, $append: true) {
	@if ($tmp-transition-value == none) {
		$tmp-transition-value: $value !global;
	}
	@else {
		$tmp-transition-value: ($tmp-transition-value, $value) !global;
	}

	transition: #{$tmp-transition-value};

	@if ($append == false) {
		$tmp-transition-value: none !global;
	}
}
