.form-callback {
	&__block-header {
		margin-bottom: 10px;
		font-size: 26px;
		line-height: 1.35;
		color: $color-Mine-Shaft;
	}

	&__block-text {
		margin-bottom: 24px;
		font-weight: 300;
	}

	&__block-phone {
		margin-top: 50px;
		margin-bottom: 50px;
		font-weight: 700;
		font-size: 32px;
	}

	&__block-input {
		margin-bottom: 20px;
		outline: none;
		border: none;
		border-bottom: 2px solid $color-Gray;
		width: 80%;
		font-weight: 300;
	}

	&.form-callback_center {
		text-align: center;

		.form-callback__block-phone {
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.form-callback__block-input {
			text-align: center;
		}

		.button {
			display: inline-block;
		}
	}

	&.form-callback_mini {
		.form-callback__block-header,
		.form-callback__block-phone {
			display: none;
		}
	}
}
