$sprites: (
	"logo": (
		normal: (
			x: 0,
			y: 0,
			width: 115,
			height: 35,
			image: "sprites.png",
			total_width: 115,
			total_height: 45,
		),
		retina: (
			x: 0,
			y: 0,
			width: 230,
			height: 70,
			image: "sprites@2x.png",
			total_width: 230,
			total_height: 90,
		),
	),
	"tick": (
		normal: (
			x: 0,
			y: 37,
			width: 12,
			height: 8,
			image: "sprites.png",
			total_width: 115,
			total_height: 45,
		),
		retina: (
			x: 0,
			y: 74,
			width: 24,
			height: 16,
			image: "sprites@2x.png",
			total_width: 230,
			total_height: 90,
		),
	),
);

@each $name in map-keys($sprites) {
	.sprite-#{$name} {
		@include sprite($name);
	}
}
