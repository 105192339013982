// stylelint-disable scss/dollar-variable-empty-line-before

// COLORS

$color-Black:#000000;
$color-Dodger-Blue:#00B4FF;
$color-Cod-Gray:#171717;
$color-Mine-Shaft:#292929;
$color-Turtle-Green:#2B3B0B;
$color-Verdun-Green:#354C04;
$color-Mine-Shaft:#383838;
$color-Green-Leaf:#3C5409;
$color-Chambray:#3D5A98;
$color-Bossanova:#402544;
$color-Tundora:#404040;
$color-Limeade:#4B8C00;
$color-Bossanova:#512957;
$color-Steel-Blue:#5181B8;
$color-Limeade:#559609;
$color-Scorpion:#575757;
$color-Limeade:#57A300;
$color-Voodoo:#58405C;
$color-Limeade:#60B301;
$color-Limeade:#61B204;
$color-Honey-Flower:#631A6E;
$color-Eminence:#65296E;
$color-Dove-Gray:#707070;
$color-Christi:#7EB20F;
$color-Lima:#82B416;
$color-Lima:#84B51B;
$color-Gray:#8C8C8C;
$color-Sushi:#95BF3A;
$color-Olivine:#A9BB82;
$color-Silver:#BDBDBD;
$color-Silver:#C4C4C4;
$color-Silver:#C7C7C7;
$color-Alto:#DBDBDB;
$color-Gallery:#EBEBEB;
$color-Gallery:#F0F0F0;
$color-Tangerine:#F18A00;
$color-Concrete:#F2F2F2;
$color-Wild-Sand:#F4F4F4;
$color-Alabaster:#F9F9F9;
$color-Alabaster:#FCFCFC;
$color-White:#FFFFFF;

$color-Black-016:rgba(0, 0, 0, 0.16); // #000
$color-Black-02:rgba(0, 0, 0, 0.2); // #000
$color-Black-025:rgba(0, 0, 0, 0.25); // #000
$color-Black-036:rgba(0, 0, 0, 0.36); // #000
$color-Black-055:rgba(0, 0, 0, 0.55); // #000
$color-Silver-059:rgba(189, 189, 189, 0.59); // #BDBDBD
$color-Silver-019:rgba(196, 196, 196, 0.19); // #C4C4C4
$color-Cod-Gray-031:rgba(26, 26, 26, 0.31); // #1A1A1A
$color-Cod-Gray-062:rgba(26, 26, 26, 0.62); // #1A1A1A
$color-Tundora-003:rgba(66, 66, 66, 0.03); // #424242

// FONTS

$font-family-Roboto: "Roboto", sans-serif;
$font-family-Circe: "Circe", sans-serif;

// FOUNDATION

$body-font-family: $font-family-Circe;

$dropdown-menu-item-color-active: $color-Limeade;
// $dropdownmenu-min-width: 100%;
$dropdownmenu-border: none;

// $anchor-color-hover: #c00;
// $anchor-text-decoration-hover: underline;

$tab-background-active: $color-White;

$topbar-background: $color-White;

$titlebar-background: $color-White;

// 25. Off-canvas
// --------------
$offcanvas-background: $color-White;
$offcanvas-sizes: (
  small: 500px,
);
$offcanvas-exit-background: $color-Black-036;
