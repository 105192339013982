@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700&subset=cyrillic");

@font-face {
	src: url("../fonts/Circe-ExtraLight.woff2") format("woff2"), url("../fonts/Circe-ExtraLight.woff") format("woff");
	font-family: "Circe";
	font-weight: 100;
	font-style: normal;
}

@font-face {
	src: url("../fonts/Circe-Light.woff2") format("woff2"), url("../fonts/Circe-Light.woff") format("woff");
	font-family: "Circe";
	font-weight: 300;
	font-style: normal;
}

@font-face {
	src: url("../fonts/Circe-Regular.woff2") format("woff2"), url("../fonts/Circe-Regular.woff") format("woff");
	font-family: "Circe";
	font-weight: 400;
	font-style: normal;
}

@font-face {
	src: url("../fonts/Circe-Bold.woff2") format("woff2"), url("../fonts/Circe-Bold.woff") format("woff");
	font-family: "Circe";
	font-weight: 700;
	font-style: normal;
}

@font-face {
	src: url("../fonts/Circe-ExtraBold.woff2") format("woff2"), url("../fonts/Circe-ExtraBold.woff") format("woff");
	font-family: "Circe";
	font-weight: 900;
	font-style: normal;
}
